<template>
  <div>
    <page-header>
      <template v-slot:right>
        <router-link to="/cms/article/edit" style="margin-left: 10px">
          <el-button type="primary">添加文章</el-button>
        </router-link>
        <el-button plain @click="handleOpenImportDialog" style="margin-left: 10px">文章导入</el-button>
        <el-button plain @click="handleBatchPublish">批量发布</el-button>
        <el-button plain @click="handleBatchOut">批量下架</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="importLoading" element-loading-text="正在导入，请稍等...">
        <content-condition @change="handleConditionChange"></content-condition>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                @selection-change="handleSelectionChange"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="title" label="标题" width="500">
                <template slot-scope="scope">
                  <router-link :to="{ name: 'ArticleEdit', query: { id: scope.row.id }}">
                    <el-button type="text">{{ scope.row.title }}</el-button>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="categoryName" label="分类" width="120"></el-table-column>
              <el-table-column label="统计">
                <template slot-scope="scope">
                  <i class="el-icon-share art-icon-item" title="分享次数">{{ scope.row.countShare || 0 }}</i>
                  <i class="el-icon-view art-icon-item" title="浏览次数">{{ scope.row.countView || 0 }}</i>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                  <dict-text :code="scope.row.status" dict-name="cmsMaterialStatus"></dict-text>
                </template>
              </el-table-column>
              <el-table-column prop="ipTop" label="置顶">
                <template slot-scope="scope">
                  {{ scope.row.isTop ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="isPush" label="推送">
                <template slot-scope="scope">
                  {{ scope.row.isPush ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
              <el-table-column prop="publishTime" label="发布时间" width="140"></el-table-column>
              <el-table-column
                  label="操作"
                  fixed="right"
                  width="120">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.status !== 2" @click="handlePublish([scope.row.id])" type="text">发布
                  </el-button>
                  <el-button v-if="scope.row.status === 2" @click="handleOut([scope.row.id])" type="text">下架
                  </el-button>
                  <el-button v-if="scope.row.status !== 2" @click="handleRemove([scope.row.id])" type="text">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
        <el-dialog
            title="文章导入"
            :visible.sync="showImportDialog"
            :close-on-click-modal="false"
            width="50%"
        >
          <el-alert type="warning" :closable="false" class="tips">
            <template slot="title">
              <p>文章导入不保证内容完全正确，导入成功后，请核对</p>
            </template>
          </el-alert>
          <el-form
              :model="articleImportForm"
              ref="articleImportForm"
              label-width="70px"
              label-position="right"
          >
            <el-form-item label="分类">
              <category-select v-model="articleImportForm.categoryIds"></category-select>
            </el-form-item>
            <el-form-item label="来源">
              <el-select v-model="articleImportForm.parserType" placeholder="请选择" style="width: 100px">
                <el-option
                    v-for="item in parserType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文章地址">
              <el-input type="textarea" placeholder="请输入文章详情页地址，多个回车换行" :rows="5" v-model="articleImportForm.pageUrl"
                        class="input-with-select">
              </el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button plain @click="showImportDialog=false">取 消</el-button>
            <el-button type="primary" @click="handleParse()">确 定</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CmsArticleApi } from '@/api'
import { PARSER_TYPE } from '@/utils/contants'
import ContentCondition from './components/ContentCondition'

export default {
  name: 'Article',
  components: { ContentCondition },
  data () {
    return {
      searchForm: {
        title: '',
        status: '',
        categoryId: '',
        pushStatus: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      importLoading: false,
      selectRows: [],
      showImportDialog: false,
      parserType: PARSER_TYPE,
      articleImportForm: {
        categoryIds: [],
        pageUrl: '',
        parserType: 'WEIXIN'
      }
    }
  },
  methods: {
    handleConditionChange (data) {
      this.searchForm = { ...data }
      this.handleCurrentChange(1)
    },
    handleSearch () {
      this.loading = true
      const params = {
        ...this.searchForm,
        page: this.page - 1,
        size: this.size
      }
      CmsArticleApi.list(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleSelectionChange (val) {
      this.selectRows = val
    },
    handleBatchRemove () {
      if (this.selectRows.length === 0) {
        this.$message.error('请选择要删除的数据')
        return
      }
      const ids = this.selectRows.map(row => {
        return row.id
      })
      this.remove(ids)
    },
    handleBatchPublish () {
      if (this.selectRows.length === 0) {
        this.$message.error('请选择要操作的数据')
        return
      }
      const ids = this.selectRows.map(row => {
        return row.id
      })
      this.handlePublish(ids)
    },
    handleBatchOut () {
      if (this.selectRows.length === 0) {
        this.$message.error('请选择要操作的数据')
        return
      }
      const ids = this.selectRows.map(row => {
        return row.id
      })
      this.handleOut(ids)
    },
    handleRemove (ids) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsArticleApi.remove(ids).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handlePublish (ids) {
      this.$confirm('确定要发布吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsArticleApi.publish(ids).then(() => {
          this.$message.success('发布成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleOut (ids) {
      this.$confirm('确定要下架吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsArticleApi.out(ids).then(() => {
          this.$message.success('操作成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleOpenImportDialog () {
      this.showImportDialog = true
      this.articleImportForm = {
        categoryIds: [],
        pageUrl: '',
        parserType: 'WEIXIN'
      }
    },
    valid () {
      if (!this.articleImportForm.parserType || this.articleImportForm.parserType.length === 0) {
        this.$message.error('请选择解析器类型')
        return false
      }
      if (!this.articleImportForm.pageUrl || this.articleImportForm.pageUrl.length === 0) {
        this.$message.error('请输文章地址')
        return false
      }

      if (this.articleImportForm.categoryIds.length === 0) {
        this.$message.error('请选择分类')
        return false
      }
      return true
    },
    handleParse () {
      if (!this.valid()) {
        return
      }
      this.importLoading = true
      this.showImportDialog = false
      const data = { ...this.articleImportForm }
      data.pageUrl = data.pageUrl.split('\n')
      console.log(data)
      CmsArticleApi.importArticle(data).then((id) => {
        this.importLoading = false
        this.$message.success('导入成功')
        this.handleCurrentChange(1)
      }).catch(() => {
        this.importLoading = false
      })
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style>
.art-icon-item {
  margin-left: 5px;
}

.tips {
  margin-bottom: 20px;
}

</style>
